import { AsyncThunk, createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authApi } from 'api/auth-service';
import * as models from 'api/models/auth';
import { RootState } from 'app/store';
import { ProblemDetails } from 'utils/problem-details';

export interface UsersState {
  users: models.UserDoc[];
  isLoading: boolean;
  error: ProblemDetails | null;
}

const initialState: UsersState = {
  users: [],
  isLoading: false,
  error: null
};

export const getAllUsers: AsyncThunk<models.UserDoc[], models.UserInfo, {state: RootState}> = createAsyncThunk(
  'users/getAllUsers',
  async (user, {rejectWithValue}) => {
    try {

       return await authApi.getAllUsers(user);

    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getAllUsersPending = createAction(getAllUsers.pending.type),
  getAllUsersFulfilled = createAction<models.UserDoc[]>(getAllUsers.fulfilled.type),
  getAllUsersRejected = createAction<ProblemDetails>(getAllUsers.rejected.type);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {

  },
  extraReducers: builder =>
    builder
      .addCase(getAllUsersPending, state => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(getAllUsersFulfilled, (state, {payload}) => {
        state.isLoading = false;
        state.users = payload;
      })
      .addCase(getAllUsersRejected, (state, {payload}) => {
        state.isLoading = false;
        state.error = payload;
      })
});

export const selectUsers = (state: RootState) => state.users.users;
export const selectIsLoading = (state: RootState) => state.users.isLoading;
export const selectError = (state: RootState) => state.users.error;

export default usersSlice.reducer;
