import { AsyncThunk, createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createPlant, Plant } from 'api/models/plants';
import { plantApi } from 'api/plant-service';
import { RootState } from 'app/store';
import { ProblemDetails } from 'utils/problem-details';

interface PlantDetailState {
  isLoading: boolean;
  plant: Plant
  error: ProblemDetails | null;
}

const initialState: PlantDetailState = {
  isLoading: false,
  plant: createPlant(),
  error: null
};

export const savePlant: AsyncThunk<Plant, void, {state: RootState}> = createAsyncThunk(
  'plant-detail/save-plant',
  async (_, {rejectWithValue, getState}) => {
    try {

      const plant = (getState() as RootState).plantDetail.plant,
        doc = {...plant};
      
      const updated = await plantApi.save(doc);
      return updated;

    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const deletePlant: AsyncThunk<void, void, {state: RootState}> = createAsyncThunk(
  'plant-detail/delete-plant',
  async (_, {rejectWithValue, getState}) => {
    try {

      const plant = (getState() as RootState).plantDetail.plant,
        doc = {...plant};
      
      const updated = await plantApi.delete(doc);
      return updated;

    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const savePending = createAction(savePlant.pending.type),
  saveFulfilled = createAction<Plant | undefined>(savePlant.fulfilled.type),
  saveRejected = createAction<ProblemDetails>(savePlant.rejected.type),
  deletePending = createAction(deletePlant.pending.type),
  deleteFulfilled = createAction(deletePlant.fulfilled.type),
  deleteRejected = createAction<ProblemDetails>(deletePlant.rejected.type);

export const plantDetailSlice = createSlice({
  name: 'plant-detail',
  initialState,
  reducers: {
    setPlant(state, action: PayloadAction<Plant>) {
      state.plant = action.payload;
    }
  },
  extraReducers: builder =>
    builder
      .addCase(savePending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(saveFulfilled, (state, action) => {
        state.isLoading = false;
        if(action.payload) {
          state.plant = action.payload;
        }
      })
      .addCase(saveRejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deletePending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteFulfilled, (state, action) => {
        state.isLoading = false;
        state.plant = createPlant();
      })
      .addCase(deleteRejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
});

export const { setPlant } = plantDetailSlice.actions;

export const selectPlant = (state: RootState) => state.plantDetail.plant;
export const selectIsLoading = (state: RootState) => state.plantDetail.isLoading;
export const selectError = (state: RootState) => state.plantDetail.error;

export default plantDetailSlice.reducer;
