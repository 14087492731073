import * as models from './models/plants';
import { ServiceBase } from './service-base';

class PlantService extends ServiceBase {
  getAll() {
    return this.getAllDocuments<models.Plant>('filters/plants');
  }

  async save(plant: models.Plant) {
    return this.saveDocument<models.Plant>(plant);
  }
}

export const plantApi = new PlantService();
