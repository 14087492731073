import React from 'react';
import { Navigate, useLocation } from 'react-router';
import { useAuth } from './use-auth';
import { Loading } from 'features/loading/Loading';

interface RequireAuthProps {
  element: React.LazyExoticComponent<() => JSX.Element>;
}

export function RequireAuth({element: ChildElement}: RequireAuthProps) {
  const auth = useAuth(),
    location = useLocation();

  if(!auth?.user) {
    return <Navigate to="/login" state={{from: location}} replace />;
  }

  return <React.Suspense fallback={<Loading />}>
      <ChildElement />
    </React.Suspense>;
}
