import * as models from './models/zones';
import { ServiceBase } from './service-base';

class ZoneService extends ServiceBase {
  getAll() {
    return this.query<models.Zone>('filters/zones');
  }

  save(zone: models.Zone) {
    return this.saveDocument<models.Zone>(zone);
  }
}

export const zoneApi = new ZoneService();
