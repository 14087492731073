import * as models from './models/orders';
import { ServiceBase } from './service-base';

class OrderService extends ServiceBase {
  getAll() {
    return this.getAllDocuments<models.Order>('filters/orders');
  }

  save(order: models.Order) {
    return this.saveDocument<models.Order>(order);
  }

  byStickDate(from: string, to: string) {
    return this.query<models.Order>('filters/orders-by-stick-date', from, to);
  }

  byFlowerDate(from: string, to: string) {
    return this.query<models.Order>('filters/orders-by-flower-date', from, to);
  }

  bySpaceDate(from: string, to: string) {
    return this.query<models.Order>('filters/orders-by-space-date', from, to);
  }

  byPinchDate(from: string, to: string) {
    return this.query<models.Order>('filters/orders-by-pinch-date', from, to);
  }
}

export const orderApi = new OrderService();
