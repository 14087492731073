import React, { useState } from 'react';
import { AuthContext } from './auth-context';
import { authApi } from 'api/auth-service';
import { Roles, UserInfo } from 'api/models/auth';
import { events, EventTypes } from 'app/events';
import { equals } from 'utils/equals';

const storage_key = 'auth_token';

interface AuthProviderProps {
  children: React.ReactNode;
}

export function AuthProvider({children}: AuthProviderProps) {
  const [user, setUser] = useState<UserInfo | null>(getUserInfo());

  const signin = async (name: string, password: string) => {
    try {
      const doc = await authApi.login(name, password),
        user = doc ? {name, password, roles: doc.roles, email: (doc.email || null), phone: (doc.phone || null)} : null;

      saveUserInfo(user);
      setUser(user);
      if(user) {
        events.emit(EventTypes.authenticated);
      }

    } catch(e) {
      throw e;
    }
  }

  const signout = (callback: VoidFunction) => {
    setUser(null);
    saveUserInfo();
    callback();
  };

  const isInRole = (role: Roles) => {
    if(!user || !Array.isArray(user?.roles)) {
      return false;
    }
    
    return user.roles.some(r => equals(r, role));
  };

  const value = {user, signin, signout, isInRole};

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function saveUserInfo(user?: UserInfo | null) {
  if(user) {
    localStorage[storage_key] = JSON.stringify(user);
  } else {
    localStorage.removeItem(storage_key);
  }
}

export function getUserInfo(): UserInfo | null {
  return JSON.parse(localStorage[storage_key] || null);
}
