export function equals(
  a: string | null | undefined,
  b: string | null | undefined
): boolean {
  if (a == null || b == null) return false;
  return a.toLowerCase() === b.toLowerCase();
}

export function contains(str: string | null, value: string | null) {
  return (str || '').toLowerCase().indexOf((value || '').toLowerCase()) !== -1;
}
