const isDebug = window.location.hostname === 'localhost',
  remote_server =
    'https://7bff221b-c372-4033-bf6c-59d2e859a520-bluemix.cloudant.com',
  db = isDebug ? 'boekestyn-test' : 'boekestyn-v4',
  remote_database_name = `${remote_server}/${db}`;

export const configuration = {
  remote_server,
  remote_database_name,
  app_database_name: 'greenrpv5',
  obsolete_database_name: 'greenrpv4',
};
