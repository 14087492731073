import { UserInfo, CouchUserDoc } from './models/auth';
import * as models from './models/driver-tasks';
import { api } from './api-base';
import { ServiceBase } from './service-base';
import { getBasicAuth } from './auth-service';
import { configuration } from 'features/auth/configuration';
import { routes } from 'app/routes';

class DriverTasksService extends ServiceBase {
  driverTasks() {
    return this.query<models.DriverTask>('filters/driver-tasks');
  }

  getOneDriverTask(id: string): Promise<models.DriverTask> {
    return this.getOneDocument(id);
  }

  async getDrivers(user: UserInfo): Promise<models.Driver[]> {
    const url = `${configuration.remote_server}/_users/_design/user-filters/_view/boekestyn-drivers?include_docs=true`,
      authorization = getBasicAuth(user.name, user.password),
      headers = { Authorization: authorization },
      response = await api.get<PouchDB.Query.Response<CouchUserDoc>>(url, {
        headers,
      }),
      drivers = response.rows
        .flatMap((row) => (row.doc ? [row.doc] : []))
        .map((row) => ({
          name: row.name,
          email: row.email || null,
          phone: row.phone || null,
        }));

    return drivers;
  }

  async createDriverTask(
    user: UserInfo,
    dueDate: string,
    assignedTo: models.Driver | null,
    priority: models.Priority,
    notes: string,
    status: models.Status,
    fromLocation: string,
    toLocation: string,
    sendTextNotification: boolean = false
  ) {
    const driverTask = {
      ...models.createDriverTask(user),
      dueDate,
      assignedTo,
      priority,
      notes,
      status,
      fromLocation,
      toLocation,
    };

    await this.saveDocument(driverTask);

    if (assignedTo?.phone && sendTextNotification) {
      const link = `${window.location.origin}${routes.driverTasks.list.path}`,
        message = `${user.name} has assigned you a task:\n\n${notes}`,
        phoneNumber = assignedTo.phone;

      this.sendTextNotification({ user, phoneNumber, link, message });
    }
  }

  updateDriverTask(driverTask: models.DriverTask) {
    return this.saveDocument(driverTask);
  }

  sendTextNotification({
    user,
    phoneNumber,
    link,
    message,
  }: TextNotificationArgs) {
    const authorization = getBasicAuth(user.name, user.password),
      headers = { Authorization: authorization },
      model = { phoneNumber, link, message };

    api.post('/notifications/driver-task', model, { headers });
  }
}

export interface TextNotificationArgs {
  user: UserInfo;
  phoneNumber: string;
  link: string;
  message: string;
}

export const driverTasksApi = new DriverTasksService();
