import { guid } from 'utils/guid';
import { ModelBase } from './model-base';

export const PlantType = 'plant';

export interface Plant extends ModelBase {
  type: string;
  name: string;
  abbreviation: string;
  crop: string;
  size: string;
  colour?: string | null;
  cuttingsPerPot: number;
  cuttingsPerTableTight: number;
  cuttingsPerTableSpaced: number;
  cuttingsPerTablePartiallySpaced: number;
  potsPerCase: number;
  hasLightsOut: boolean;
  hasPinching: boolean;
  pinchingPotsPerHour: number;
  daysToPinch?: number;
  stickingCuttingsPerHour: number;
  spacingPotsPerHour: number;
  packingCasesPerHour: number;
  varieties?: Variety[];
}

export interface Variety {
  name: string;
}

export function createPlant(basePlant?: Plant) {
  const plant: Plant = {
    _id: guid(),
    type: PlantType,
    name: '',
    abbreviation: '',
    crop: '',
    size: '',
    cuttingsPerPot: 1,
    cuttingsPerTableTight: 1,
    cuttingsPerTableSpaced: 1,
    cuttingsPerTablePartiallySpaced: 1,
    potsPerCase: 1,
    hasLightsOut: true,
    hasPinching: false,
    pinchingPotsPerHour: 0,
    stickingCuttingsPerHour: 1,
    spacingPotsPerHour: 1,
    packingCasesPerHour: 1
  }

  if(basePlant) {
    Object.assign(plant, basePlant);
  }

  return plant;
}
