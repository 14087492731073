import { getBasicAuth } from './auth-service';
import { axios } from 'boot/axios';
import { getUserInfo } from 'features/auth/auth-provider';
import { AxiosResponseHeaders, ResponseType } from 'axios';

const type =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  responseType: ResponseType = 'blob';

class ReportsService {
  async orderSummary(start: string, end: string, plant: string | null) {
    const config = getConfig(),
      response = await axios.get(
        `/reports/orders/summary?start=${start}&end=${end}&plant=${
          plant || ''
        }`,
        config
      ),
      { headers, data } = response,
      blob = new Blob([data], { type }),
      filename = getFilename(headers as AxiosResponseHeaders) || 'Orders.xlsx';

    downloadFile(blob, filename);
  }

  async ordersByStickDate(start: string, end: string, plant: string | null) {
    const config = getConfig(),
      response = await axios.get(
        `/reports/orders/by-stick-date?start=${start}&end=${end}&plant=${
          plant || ''
        }`,
        config
      ),
      { headers, data } = response,
      blob = new Blob([data], { type }),
      filename =
        getFilename(headers as AxiosResponseHeaders) || 'ByStickDate.xlsx';

    downloadFile(blob, filename);
  }

  async ordersByPinchDate(start: string, end: string, plant: string | null) {
    const config = getConfig(),
      response = await axios.get(
        `/reports/orders/by-pinch-date?start=${start}&end=${end}&plant=${
          plant || ''
        }`,
        config
      ),
      { headers, data } = response,
      blob = new Blob([data], { type }),
      filename =
        getFilename(headers as AxiosResponseHeaders) || 'ByPinchDate.xlsx';

    downloadFile(blob, filename);
  }

  async ordersByFlowerDate(start: string, end: string, plant: string | null) {
    const config = getConfig(),
      response = await axios.get(
        `/reports/orders/by-flower-date?start=${start}&end=${end}&plant=${
          plant || ''
        }`,
        config
      ),
      { headers, data } = response,
      blob = new Blob([data], { type }),
      filename =
        getFilename(headers as AxiosResponseHeaders) || 'ByFlowerDate.xlsx';

    downloadFile(blob, filename);
  }

  async ordersBySpaceDate(start: string, end: string, plant: string | null) {
    const config = getConfig(),
      response = await axios.get(
        `/reports/orders/by-space-date?start=${start}&end=${end}&plant=${
          plant || ''
        }`,
        config
      ),
      { headers, data } = response,
      blob = new Blob([data], { type }),
      filename =
        getFilename(headers as AxiosResponseHeaders) || 'BySpaceDate.xlsx';

    downloadFile(blob, filename);
  }

  async labourReport(year: number) {
    const config = getConfig(),
      response = await axios.get(`/reports/labour-hours?year=${year}`, config),
      { headers, data } = response,
      blob = new Blob([data], { type }),
      filename =
        getFilename(headers as AxiosResponseHeaders) || 'LabourHours.xlsx';

    downloadFile(blob, filename);
  }
}

function getConfig() {
  const userInfo = getUserInfo(),
    name = userInfo?.name || '',
    password = userInfo?.password || '',
    auth = getBasicAuth(name, password),
    config = { headers: { Authorization: auth }, responseType };
  return config;
}

function getFilename(headers: AxiosResponseHeaders) {
  const contentDisposition = headers['content-disposition'] || '',
    dispositionParts = contentDisposition.split(';'),
    filenamePart =
      dispositionParts.find((a) => a.indexOf('filename') !== -1) || '',
    filename = filenamePart.replace(' filename=', '');

  return filename;
}

function downloadFile(blob: Blob, filename: string) {
  const a = document.createElement('a');
  a.download = filename;
  a.style.display = 'none';
  a.href = URL.createObjectURL(blob);
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
}

export const reportsApi = new ReportsService();
