import { guid } from 'utils/guid';
import { ModelBase } from './model-base';

export const ZoneType = 'zone';

export interface Zone extends ModelBase {
  type: string;
  name: string;
  tables: number;
  isOffsite?: boolean;
}

export function createZone(baseZone?: Zone) {
  const zone: Zone = {
    _id: guid(),
    type: ZoneType,
    name: '',
    tables: 0,
    isOffsite: false,
  };

  if (baseZone) {
    Object.assign(zone, baseZone);
  }

  return zone;
}
