import PouchDB from 'pouchdb';
import { EventTypes, events } from 'app/events';
import { configuration } from 'features/auth/configuration';
import { getUserInfo } from 'features/auth/auth-provider';
import { createProblemDetails } from 'utils/problem-details';
import { PlantType } from 'api/models/plants';
import { CustomerType } from 'api/models/customers';
import { DriverTaskType } from 'api/models/driver-tasks';
import { ZoneType } from 'api/models/zones';
import { OrderType } from 'api/models/orders';

const obsolete = new PouchDB(configuration.obsolete_database_name);
obsolete.info().then((info) => {
  console.log('Obsolete db info', info);
});
obsolete.destroy().then((response) => {
  console.log('Obsolete db destroyed', response);
});

let localDb: PouchDB.Database | null = null;
let remoteDb: PouchDB.Database | null = null;

class Database {
  constructor() {
    this.init().then(() => {
      events.on(EventTypes.authenticated, this.init.bind(this));
    });
  }

  async init() {
    if (localDb == null) {
      localDb = new PouchDB(configuration.app_database_name);
    }

    const userInfo = getUserInfo();
    if (remoteDb == null && userInfo) {
      const opts = {
        skip_setup: true,
        auth: { username: userInfo.name, password: userInfo.password },
      };

      remoteDb = new PouchDB(configuration.remote_database_name, opts);
    }

    if (remoteDb) {
      localDb.replicate
        .from(remoteDb, { retry: true })
        .on('complete', (info) => {
          console.log('Initial replication completed. Commencing sync.', info);
          if (localDb && remoteDb) {
            localDb
              .sync(remoteDb, { live: true, retry: true, batch_size: 1000 })
              .on('complete', () => {
                console.log('Sync complete');
              })
              .on('error', (err) => {
                console.error('Sync error');
                console.error(err);
                const values = Object.values(err);
                // this happens on iOS 10/Safari. Use the API keys...
                if (
                  values.indexOf(
                    '_reader access is required for this request'
                  ) !== -1
                ) {
                  try {
                    //sync;
                  } catch (e) {}

                  localDb = null;
                  events.emit(
                    EventTypes.error,
                    createProblemDetails('Invalid Permissions')
                  );
                }
              })
              .on('change', ({ change }) => {
                console.log('Sync change');
                console.log(change);
                if (Array.isArray(change.docs)) {
                  let deleted = change.docs.some(
                      (doc) => (doc as any)._deleted
                    ),
                    ordersSynced =
                      deleted ||
                      change.docs.some(
                        (doc) => (doc as any).type === OrderType
                      ),
                    zonesSynced =
                      deleted ||
                      change.docs.some((doc) => (doc as any).type === ZoneType),
                    customersSynced =
                      deleted ||
                      change.docs.some(
                        (doc) => (doc as any).type === CustomerType
                      ),
                    plantsSynced =
                      deleted ||
                      change.docs.some(
                        (doc) => (doc as any).type === PlantType
                      ),
                    driverTasksSynced =
                      deleted ||
                      change.docs.some(
                        (doc) => (doc as any).type === DriverTaskType
                      );

                  if (ordersSynced) {
                    events.emit(EventTypes.ordersUpdated);
                  }

                  if (customersSynced) {
                    events.emit(EventTypes.customersUpdated);
                  }

                  if (zonesSynced) {
                    events.emit(EventTypes.zonesUpdated);
                  }

                  if (plantsSynced) {
                    events.emit(EventTypes.plantsUpdated);
                  }

                  if (driverTasksSynced) {
                    events.emit(EventTypes.driverTasksUpdated);
                  }
                }
              })
              .on('paused', (info) => {
                console.log('Sync paused');
                console.log(info);
              })
              .catch((err) => {
                console.error('sync error', err);
              });
          }
        });
    }
  }

  get db() {
    if (!localDb) {
      throw createProblemDetails('Database not initialized');
    }

    return localDb;
  }
}

export const database = new Database();
