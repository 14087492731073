import moment from 'moment';
import { guid } from 'utils/guid';
import { createPlant, Plant } from './plants';
import { Customer, weeklyCustomer } from './customers';
import { createZone, Zone } from './zones';
import { ModelBase } from './model-base';

export const OrderType = 'order';

export interface Order extends ModelBase {
  type: string;
  orderNumber: string;
  customer: Customer;
  plant: Plant;
  cuttings: number;
  pots: number;
  cases: number;
  supplierPoNumber: string;
  stickDate: string;
  stickZone: Zone;
  hasPartialSpace: boolean;
  partialSpaceDate?: string;
  partialSpaceZone?: Zone;
  hasSpacing: boolean;
  fullSpaceDate?: string;
  fullSpaceZone?: Zone;
  hasLightsOut: boolean;
  lightsOutDate?: string;
  lightsOutZone?: Zone;
  hasPinching: boolean;
  pinchDate?: string;
  flowerDate: string;
  tableCountTight: number;
  tableCountPartiallySpaced?: number;
  tableCountSpaced?: number;
  notes: string | null;
  season: string | null;
  varieties?: OrderVariety[];
}

export interface OrderVariety {
  name: string;
  cuttings: number;
  pots: number;
  cases: number;
  comment: string | null;
}

export function createOrder(baseOrder?: Order) {
  const order: Order = {
    _id: guid(),
    type: OrderType,
    orderNumber: '',
    supplierPoNumber: '',
    stickDate: moment().format('YYYY-MM-DD'),
    hasPartialSpace: false,
    hasSpacing: true,
    fullSpaceDate: moment().add(1, 'week').format('YYYY-MM-DD'),
    hasLightsOut: true,
    lightsOutDate: moment().add(1, 'week').format('YYYY-MM-DD'),
    hasPinching: false,
    flowerDate: moment().add(1, 'week').format('YYYY-MM-DD'),
    cuttings: 0,
    pots: 0,
    cases: 0,
    customer: weeklyCustomer(),
    plant: createPlant(),
    stickZone: createZone(),
    fullSpaceZone: createZone(),
    tableCountTight: 1,
    tableCountSpaced: 1,
    notes: null,
    season: null
  }

  if(baseOrder) {
    Object.assign(order, baseOrder);
  }

  if(!order.orderNumber) {
    order.orderNumber = createOrderNumber(order.plant.abbreviation, order.customer.abbreviation, order.stickDate);
  }

  return order;
}

export function createOrderNumber(plantAbbreviation: string, customerAbbreviation: string, stickDate: string) {
  const abbreviation = plantAbbreviation || '',
      customer = customerAbbreviation || '',
      date = moment(stickDate),
      week = date.isoWeek(),
      year = date.isoWeekYear(),
      day = date.isoWeekday(),
      orderNumber = `${abbreviation}${customer}${year}-${week}-${day}`;

  return orderNumber;
}
