import {
  AsyncThunk,
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { orderApi } from 'api/order-service';
import { createOrder, Order } from 'api/models/orders';
import { RootState } from 'app/store';
import { ProblemDetails } from 'utils/problem-details';

interface OrderDetailState {
  isLoading: boolean;
  order: Order | null;
  error: ProblemDetails | null;
}

const initialState: OrderDetailState = {
  isLoading: false,
  order: null,
  error: null,
};

export const saveOrder: AsyncThunk<
  Order | undefined,
  void,
  { state: RootState }
> = createAsyncThunk(
  'order-detail/save-order',
  async (_, { rejectWithValue, getState }) => {
    const order = (getState() as RootState).orderDetail.order;

    if (order) {
      try {
        const doc = { ...order },
          updated = await orderApi.save(doc);

        return updated;
      } catch (e) {
        return rejectWithValue(e as ProblemDetails);
      }
    }
  }
);

export const deleteOrder: AsyncThunk<void, void, { state: RootState }> =
  createAsyncThunk(
    'order-detail/delete-order',
    async (_, { rejectWithValue, getState }) => {
      const order = (getState() as RootState).orderDetail.order;

      if (order) {
        try {
          const doc = { ...order },
            updated = await orderApi.delete(doc);

          return updated;
        } catch (e) {
          return rejectWithValue(e as ProblemDetails);
        }
      }
    }
  );

const savePending = createAction(saveOrder.pending.type),
  saveFulfilled = createAction<Order | undefined>(saveOrder.fulfilled.type),
  saveRejected = createAction<ProblemDetails>(saveOrder.rejected.type),
  deletePending = createAction(deleteOrder.pending.type),
  deleteFulfilled = createAction(deleteOrder.fulfilled.type),
  deleteRejected = createAction<ProblemDetails>(deleteOrder.rejected.type);

export const orderDetailSlice = createSlice({
  name: 'order-detail',
  initialState,
  reducers: {
    setOrder(state, action: PayloadAction<Order | null>) {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(savePending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(saveFulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.order = action.payload;
        }
      })
      .addCase(saveRejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deletePending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteFulfilled, (state) => {
        state.isLoading = false;
        state.order = createOrder();
      })
      .addCase(deleteRejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      }),
});

export const { setOrder } = orderDetailSlice.actions;

export const selectOrder = (state: RootState) => state.orderDetail.order;
export const selectIsLoading = (state: RootState) =>
  state.orderDetail.isLoading;
export const selectError = (state: RootState) => state.orderDetail.error;

export default orderDetailSlice.reducer;
