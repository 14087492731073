import { AsyncThunk, createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createZone, Zone } from 'api/models/zones';
import { zoneApi } from 'api/zone-service';
import { RootState } from 'app/store';
import { ProblemDetails } from 'utils/problem-details';

interface ZoneDetailState {
  isLoading: boolean;
  zone: Zone;
  error: ProblemDetails | null;
}

const initialState: ZoneDetailState = {
  isLoading: false,
  zone: createZone(),
  error: null
};

export const saveZone: AsyncThunk<Zone, void, {state: RootState}> = createAsyncThunk(
  'zone-detail/save-zone',
  async (_, {rejectWithValue, getState}) => {
    try {

      const zone = (getState() as RootState).zoneDetail.zone,
        doc = {...zone};
      
      const updated = await zoneApi.save(doc);
      return updated;

    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const deleteZone: AsyncThunk<void, void, {state: RootState}> = createAsyncThunk(
  'zone-detail/delete-zone',
  async (_, {rejectWithValue, getState}) => {
    try {

      const zone = (getState() as RootState).zoneDetail.zone,
        doc = {...zone};
      
      const updated = await zoneApi.delete(doc);
      return updated;

    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const savePending = createAction(saveZone.pending.type),
  saveFulfilled = createAction<Zone | undefined>(saveZone.fulfilled.type),
  saveRejected = createAction<ProblemDetails>(saveZone.rejected.type),
  deletePending = createAction(deleteZone.pending.type),
  deleteFulfilled = createAction(deleteZone.fulfilled.type),
  deleteRejected = createAction<ProblemDetails>(deleteZone.rejected.type);

export const zoneDetailSlice = createSlice({
  name: 'zone-detail',
  initialState,
  reducers: {
    setZone(state, action: PayloadAction<Zone>) {
      state.zone = action.payload;
    }
  },
  extraReducers: builder =>
    builder
      .addCase(savePending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(saveFulfilled, (state, action) => {
        state.isLoading = false;
        if(action.payload) {
          state.zone = action.payload;
        }
      })
      .addCase(saveRejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deletePending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteFulfilled, (state, action) => {
        state.isLoading = false;
        state.zone = createZone();
      })
      .addCase(deleteRejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
});

export const { setZone } = zoneDetailSlice.actions;

export const selectZone = (state: RootState) => state.zoneDetail.zone;
export const selectIsLoading = (state: RootState) => state.zoneDetail.isLoading;
export const selectError = (state: RootState) => state.zoneDetail.error;

export default zoneDetailSlice.reducer;
