import moment from 'moment';
import { guid } from 'utils/guid';
import { UserInfo } from './auth';
import { ModelBase } from './model-base';

export const DriverTaskType = 'driver-task';

export const HighPriority = 'High';
export const NormalPriority = 'Normal';
export const LowPriority = 'Low';

export type Priority = 'High' | 'Normal' | 'Low';

export const NotStartedStatus = 'Not Started';
export const InProgressStatus = 'In Progress';
export const CompleteStatus = 'Complete';

export type Status = 'Not Started' | 'In Progress' | 'Complete';

export interface Driver {
  name: string;
  email: string | null;
  phone: string | null;
}

export interface DriverTask extends ModelBase {
  type: string;
  createdBy: string;
  createdOn: string;
  dueDate: string;
  assignedTo: Driver | null;
  priority: Priority;
  notes: string;
  status: Status;
  fromLocation: string;
  toLocation: string;
}

export function createDriverTask(who: UserInfo): DriverTask {
  return {
    _id: guid(),
    type: DriverTaskType,
    createdBy: who.name,
    createdOn: moment().toISOString(),
    dueDate: moment().format('YYYY-MM-DD'),
    assignedTo: null,
    priority: 'Normal',
    notes: '',
    status: 'Not Started',
    fromLocation: '',
    toLocation: '',
  };
}
