import moment from 'moment';
import numeral from 'numeral';

export function formatDate(date: any, format = 'W[-]E [(]MMM D[)]'): string {
  if(date == null) {
    return '';
  }

  const m = moment(date);

  if(!m.isValid()) {
    return '';
  }

  return m.format(format);
}

export function formatNumber(value: any, format = '0,0'): string {
  if(value == null) {
    return '0';
  }

  return numeral(value).format(format);
}

export function formatCurrency(value: any, format = '$0,0.00'): string {
  if(value == null) {
    return '$0.00';
  }

  return numeral(value).format(format);
}

export function parseWeekAndDay(weekAndDay: string, shouldBeFuture: boolean = false) {
  const regex = /(\d{1,2})-(\d)/;
  
  if(regex.test(weekAndDay)) {
    const match = regex.exec(weekAndDay),
      week = parseInt(match?.[1] || ''),
      day = parseInt(match?.[2] || '');

    if(week && day) {
      const m = moment().isoWeek(week).isoWeekday(day);
      if(m.isValid()) {
        if(shouldBeFuture && m.isBefore()) {
          m.add(1, 'year').isoWeek(week).isoWeekday(day);
        }
        return m.toDate();
      }
    }
  }

  return null;
}

export function toWeekAndDay(date: any) {
  return formatDate(date, 'W[-]E');
}

export function parseWeekAndYear(weekAndYear: string, endOfWeek?: boolean, shouldBeFuture: boolean = false) {
  const regex = /(\d{1,2})\/(\d{4})/;
  
  if(regex.test(weekAndYear)) {
    const match = regex.exec(weekAndYear),
      week = parseInt(match?.[1] || ''),
      year = parseInt(match?.[2] || '');

    if(week && year) {
      const m = moment().isoWeek(week).isoWeekYear(year)[endOfWeek ? 'endOf' : 'startOf']('isoWeek');
      if(m.isValid()) {
        if(shouldBeFuture && m.isBefore()) {
          m.add(1, 'year').isoWeek(week).isoWeekYear(year)[endOfWeek ? 'endOf' : 'startOf']('isoWeek');
        }
        return m.toDate();
      }
    }
  }

  return null;
}

export function toWeekAndYear(date: any) {
  return formatDate(date, 'WW[/]YYYY');
}
