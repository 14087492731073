import { ProblemDetails } from 'utils/problem-details';
import { Alert } from 'reactstrap';

interface ErrorProps {
  error: ProblemDetails | null;
  clearError?: () => void;
}

export function Error({error, clearError}: ErrorProps) {
  return <Alert color="neutral" className="text-danger" toggle={clearError} isOpen={!!error}>
    <p className="lead text-capitalize">{error?.title}</p>
    {!!error?.detail &&
      <p>{error.detail}</p>
    }
  </Alert>;
}
