import React, { useState } from 'react'
import { Button, FormGroup, Input, Label } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router';
import { useAuth } from './use-auth';
import { routes } from 'app/routes';
import { Error } from 'features/error/Error';
import { createProblemDetails, ProblemDetails } from 'utils/problem-details';

export function Login() {
  const auth = useAuth(),
    navigate = useNavigate(),
    location = useLocation(),
    [name, setName] = useState(''),
    [password, setPassword] = useState(''),
    [error, setError] = useState<ProblemDetails | null>(null),
    state = location.state as any,
    from = state?.from?.pathname;

  const handleLoginClick = async (e: React.MouseEvent) => {
    e.preventDefault();

    setError(null);

    try {

      await auth.signin(name, password);

      const path = (!from || from === routes.login.path) ? routes.home.path : from;

      navigate(path, { replace: true})

    } catch(e) {
      setError(createProblemDetails(e));
    }
  };

  const clearError = () => {
    setError(null);
  };

  return (
    <form id="login" className="mt-5">
      <div id="login-wrapper" className="col-12 col-md-6 col-lg-4 mx-auto border p-3 rounded shadow">
        <div className="text-center">
          <img src="/images/logo.png" alt="Boekestyn Greenhouses" style={{maxWidth: '200px'}} />
        </div>
        <h1 className="col-12 mb-3 text-center">Boekestyn Greenhouses Resource Planning App</h1>
        <FormGroup floating className="mb-3">
          <Input id="name" value={name} onChange={e => setName(e.target.value)} placeholder="Username" autoFocus />
          <Label htmlFor="name">Username</Label>
        </FormGroup>
        <FormGroup floating className="mb-3">
          <Input id="password" type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" />
          <Label htmlFor="password">Password</Label>
        </FormGroup>
        <Error error={error} clearError={clearError} />
        <div className="text-center">
          <Button type="submit" color="primary" size="lg" onClick={handleLoginClick}>Login</Button>
        </div>
      </div>      
    </form>
  );
}

export default Login;
