import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import orders from 'features/orders/orders-slice';
import orderDetail from 'features/orders/detail-slice';
import plants from 'features/plants/plants-slice';
import plantDetail from 'features/plants/detail-slice';
import zones from 'features/zones/zones-slice';
import zoneDetail from 'features/zones/detail-slice';
import customers from 'features/customers/customers-slice';
import customerDetail from 'features/customers/detail-slice';
import users from 'features/users/users-slice';
import driverTaskList from 'features/driver-tasks/driver-task-slice';

export const store = configureStore({
  reducer: {
    orders,
    orderDetail,
    plants,
    plantDetail,
    zones,
    zoneDetail,
    customers,
    customerDetail,
    users,
    driverTaskList,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
