import * as models from './models/customers';
import { ServiceBase } from './service-base';

class CustomerService extends ServiceBase {
  getAll() {
    return this.query<models.Customer>('filters/customers');
  }

  save(customer: models.Customer) {
    return this.saveDocument<models.Customer>(customer);
  }
}

export const customerApi = new CustomerService();
