import {
  AsyncThunk,
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import moment from 'moment';
import { reportsApi } from 'api/reports-service';
import { Order } from 'api/models/orders';
import { RootState } from 'app/store';
import { sortBy } from 'utils/sort';
import { ProblemDetails } from 'utils/problem-details';
import { parseWeekAndYear, toWeekAndYear } from 'utils/format';
import { equals } from 'utils/equals';

const StartWeekKey = 'Orders List Start Week',
  EndWeekKey = 'Order List End Week',
  PlantNameKey = 'Order List Plant Name';
const startWeek = localStorage[StartWeekKey] || toWeekAndYear(new Date()),
  endWeek = localStorage[EndWeekKey] || toWeekAndYear(new Date()),
  plantName = localStorage[PlantNameKey] || null;

export interface OrdersState {
  orders: Order[];
  downloading: boolean;
  startWeek: string;
  endWeek: string;
  plantName: string | null;
}

const initialState: OrdersState = {
  orders: [],
  downloading: false,
  startWeek,
  endWeek,
  plantName,
};

interface DownloadOrdersArgs {
  from: string;
  to: string;
  plant: string | null;
}

export const downloadOrderSummary: AsyncThunk<
  undefined,
  DownloadOrdersArgs,
  { state: RootState }
> = createAsyncThunk(
  'orders/download-order-summary',
  async ({ from, to, plant }, { rejectWithValue }) => {
    try {
      await reportsApi.orderSummary(from, to, plant);
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const downloadByFlowerDate: AsyncThunk<
  undefined,
  DownloadOrdersArgs,
  { state: RootState }
> = createAsyncThunk(
  'orders/download-by-flower-date',
  async ({ from, to, plant }, { rejectWithValue }) => {
    try {
      await reportsApi.ordersByFlowerDate(from, to, plant);
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const downloadBySpaceDate: AsyncThunk<
  undefined,
  DownloadOrdersArgs,
  { state: RootState }
> = createAsyncThunk(
  'orders/download-by-space-date',
  async ({ from, to, plant }, { rejectWithValue }) => {
    try {
      await reportsApi.ordersBySpaceDate(from, to, plant);
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const downloadByStickDate: AsyncThunk<
  undefined,
  DownloadOrdersArgs,
  { state: RootState }
> = createAsyncThunk(
  'orders/download-by-stick-date',
  async ({ from, to, plant }, { rejectWithValue }) => {
    try {
      await reportsApi.ordersByStickDate(from, to, plant);
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const downloadByPinchDate: AsyncThunk<
  undefined,
  DownloadOrdersArgs,
  { state: RootState }
> = createAsyncThunk(
  'orders/download-by-pinch-date',
  async ({ from, to, plant }, { rejectWithValue }) => {
    try {
      await reportsApi.ordersByPinchDate(from, to, plant);
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const downloadOrderSummaryPending = createAction(
    downloadOrderSummary.pending.type
  ),
  downloadOrderSummaryFulfilled = createAction(
    downloadOrderSummary.fulfilled.type
  ),
  downloadByFlowerDatePending = createAction(downloadByFlowerDate.pending.type),
  downloadByFlowerDateFulfilled = createAction(
    downloadByFlowerDate.fulfilled.type
  ),
  downloadBySpaceDateFulfilled = createAction(
    downloadBySpaceDate.fulfilled.type
  ),
  downloadBySpaceDatePending = createAction(downloadBySpaceDate.pending.type),
  downloadByStickDatePending = createAction(downloadByStickDate.pending.type),
  downloadByStickDateFulfilled = createAction(
    downloadByStickDate.fulfilled.type
  ),
  downloadByPinchDatePending = createAction(downloadByPinchDate.pending.type),
  downloadByPinchDateFulfilled = createAction(
    downloadByPinchDate.fulfilled.type
  );

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrders(state, action: PayloadAction<Order[]>) {
      state.orders = action.payload;
    },
    setStartWeek(state, { payload }: PayloadAction<string>) {
      state.startWeek = payload;
      localStorage[StartWeekKey] = payload;
    },
    setEndWeek(state, { payload }: PayloadAction<string>) {
      state.endWeek = payload;
      localStorage[EndWeekKey] = payload;
    },
    setPlantName(state, { payload }: PayloadAction<string | null>) {
      state.plantName = payload;
      if (payload == null) {
        localStorage.removeItem(PlantNameKey);
      } else {
        localStorage[PlantNameKey] = payload;
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(downloadOrderSummaryPending, (state) => {
        state.downloading = true;
      })
      .addCase(downloadOrderSummaryFulfilled, (state) => {
        state.downloading = false;
      })
      .addCase(downloadByFlowerDatePending, (state) => {
        state.downloading = true;
      })
      .addCase(downloadByFlowerDateFulfilled, (state) => {
        state.downloading = false;
      })
      .addCase(downloadBySpaceDatePending, (state) => {
        state.downloading = true;
      })
      .addCase(downloadBySpaceDateFulfilled, (state) => {
        state.downloading = false;
      })
      .addCase(downloadByStickDatePending, (state) => {
        state.downloading = true;
      })
      .addCase(downloadByStickDateFulfilled, (state) => {
        state.downloading = false;
      })
      .addCase(downloadByPinchDatePending, (state) => {
        state.downloading = true;
      })
      .addCase(downloadByPinchDateFulfilled, (state) => {
        state.downloading = false;
      }),
});

export const { setOrders, setStartWeek, setEndWeek, setPlantName } =
  ordersSlice.actions;

export const selectDownloading = (state: RootState) => state.orders.downloading;
export const selectStartWeek = (state: RootState) => state.orders.startWeek;
export const selectEndWeek = (state: RootState) => state.orders.endWeek;
export const selectPlantName = (state: RootState) => state.orders.plantName;
export const selectZones = (state: RootState) => state.zones.zones;

export const selectStartDate = createSelector(selectStartWeek, (startWeek) => {
  const weekAndYear = parseWeekAndYear(startWeek);
  if (weekAndYear) {
    return moment(weekAndYear).format('YYYY-MM-DD');
  }

  return null;
});
export const selectEndDate = createSelector(selectEndWeek, (endWeek) => {
  const weekAndYear = parseWeekAndYear(endWeek, true);
  if (weekAndYear) {
    return moment(weekAndYear).format('YYYY-MM-DD HH:mm:ss');
  }

  return null;
});
export const selectAllOrders = (state: RootState) => state.orders.orders;
const selectOrdersForDates = createSelector(
  selectAllOrders,
  selectStartDate,
  selectEndDate,
  (orders, startDate, endDate) =>
    orders
      .filter(
        (o) =>
          (!startDate || o.stickDate >= startDate) &&
          (!endDate || o.flowerDate <= endDate)
      )
      .map((o) => ({ ...o }))
      .sort(sortBy('flowerDate'))
);
export const selectPlants = createSelector(
  selectOrdersForDates,
  selectPlantName,
  (orders, plantName) =>
    orders
      .map((o) => o.plant.name)
      .reduce(
        (memo, p) => {
          if (memo.indexOf(p) === -1) {
            memo.push(p);
          }
          return memo;
        },
        [plantName] as string[]
      )
      .filter((p) => !!p)
      .sort()
);
export const selectOrders = createSelector(
  selectOrdersForDates,
  selectPlantName,
  (orders, plantName) =>
    orders.filter((o) => !plantName || equals(o.plant.name, plantName))
);

export default ordersSlice.reducer;
