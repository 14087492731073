import { AsyncThunk, createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createCustomer, Customer } from 'api/models/customers';
import { customerApi } from 'api/customer-service';
import { RootState } from 'app/store';
import { ProblemDetails } from 'utils/problem-details';

interface CustomerDetailState {
  isLoading: boolean;
  customer: Customer;
  error: ProblemDetails | null;
}

const initialState: CustomerDetailState = {
  isLoading: false,
  customer: createCustomer(),
  error: null
};

export const saveCustomer: AsyncThunk<Customer, void, {state: RootState}> = createAsyncThunk(
  'customer-detail/save-customer',
  async (_, {rejectWithValue, getState}) => {
    try {

      const customer = (getState() as RootState).customerDetail.customer,
        doc = {...customer};
      
      const updated = await customerApi.save(doc);
      return updated;

    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const deleteCustomer: AsyncThunk<void, void, {state: RootState}> = createAsyncThunk(
  'customer-detail/delete-customer',
  async (_, {rejectWithValue, getState}) => {
    try {

      const customer = (getState() as RootState).customerDetail.customer,
        doc = {...customer};
      
      const updated = await customerApi.delete(doc);
      return updated;

    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const savePending = createAction(saveCustomer.pending.type),
  saveFulfilled = createAction<Customer | undefined>(saveCustomer.fulfilled.type),
  saveRejected = createAction<ProblemDetails>(saveCustomer.rejected.type),
  deletePending = createAction(deleteCustomer.pending.type),
  deleteFulfilled = createAction(deleteCustomer.fulfilled.type),
  deleteRejected = createAction<ProblemDetails>(deleteCustomer.rejected.type);

export const customerDetailSlice = createSlice({
  name: 'customer-detail',
  initialState,
  reducers: {
    setCustomer(state, action: PayloadAction<Customer>) {
      state.customer = action.payload;
    }
  },
  extraReducers: builder =>
    builder
      .addCase(savePending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(saveFulfilled, (state, action) => {
        state.isLoading = false;
        if(action.payload) {
          state.customer = action.payload;
        }
      })
      .addCase(saveRejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deletePending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteFulfilled, (state, action) => {
        state.isLoading = false;
        state.customer = createCustomer();
      })
      .addCase(deleteRejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
});

export const { setCustomer } = customerDetailSlice.actions;

export const selectCustomer = (state: RootState) => state.customerDetail.customer;
export const selectIsLoading = (state: RootState) => state.customerDetail.isLoading;
export const selectError = (state: RootState) => state.customerDetail.customer;

export default customerDetailSlice.reducer;
