import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Customer } from 'api/models/customers';
import { RootState } from 'app/store';
import { sortBy } from 'utils/sort';

export interface CustomersState {
  customers: Customer[];
}

const initialState: CustomersState = {
  customers: []
};

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setCustomers(state, action: PayloadAction<Customer[]>) {
      state.customers = action.payload;
    }
  }
});

export const { setCustomers } = customersSlice.actions;

export const selectCustomers = (state: RootState) => state.customers.customers.map(z => ({...z})).sort(sortBy('name'));

export default customersSlice.reducer;
