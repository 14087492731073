import React from 'react';
import { Roles, UserInfo } from 'api/models/auth';

interface AuthContextType {
  user: UserInfo | null;
  signin: (user: string, password: string) => Promise<void>;
  signout: (callback: VoidFunction) => void;
  isInRole: (role: Roles) => boolean;
}

export const AuthContext = React.createContext<AuthContextType>(null!);
