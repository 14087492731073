import {
  AsyncThunk,
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { driverTasksApi } from 'api/driver-tasks-service';
import { UserInfo } from 'api/models/auth';
import * as models from 'api/models/driver-tasks';
import { RootState } from 'app/store';
import { equals } from 'utils/equals';
import { ProblemDetails } from 'utils/problem-details';
import { sortBy } from 'utils/sort';

const sortByDueDate = sortBy('dueDate');

export const UnassignedDriverKey = 'unassigned';

interface DriverTaskFilter {
  showComplete: boolean;
  from: string;
  to: string;
  priority: models.Priority | '';
  status: models.Status | '';
  assignedTo: string;
  fromLocation: string;
  toLocation: string;
}

interface DriverTaskState {
  tasks: models.DriverTask[];
  drivers: models.Driver[];
  filter: DriverTaskFilter;
  isLoading: boolean;
  error: ProblemDetails | null;
}

const initialState: DriverTaskState = {
  tasks: [],
  drivers: [],
  filter: {
    showComplete: false,
    from: '',
    to: '',
    priority: '',
    status: '',
    assignedTo: '',
    fromLocation: '',
    toLocation: '',
  },
  isLoading: false,
  error: null,
};

export const getDriverTasks: AsyncThunk<
  models.DriverTask[],
  void,
  { state: RootState }
> = createAsyncThunk(
  'driver-task-list/getDriverTasks',
  async (_, { rejectWithValue }) => {
    try {
      return await driverTasksApi.driverTasks();
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const getDrivers: AsyncThunk<
  models.Driver[],
  UserInfo,
  { state: RootState }
> = createAsyncThunk(
  'driver-task-list/getDrivers',
  async (user, { rejectWithValue }) => {
    try {
      return await driverTasksApi.getDrivers(user);
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getDriverTasksPending = createAction(getDriverTasks.pending.type),
  getDriverTasksFulfilled = createAction<models.DriverTask[]>(
    getDriverTasks.fulfilled.type
  ),
  getDriverTasksRejected = createAction<ProblemDetails>(
    getDriverTasks.rejected.type
  ),
  getDriversPending = createAction(getDrivers.pending.type),
  getDriversFulfilled = createAction<models.Driver[]>(
    getDrivers.fulfilled.type
  ),
  getDriversRejected = createAction<ProblemDetails>(getDrivers.rejected.type);

export const driverTaskListSlice = createSlice({
  name: 'driver-task-list',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    setError(state, { payload }: PayloadAction<ProblemDetails | null>) {
      state.error = payload;
    },
    setFilter(state, { payload }: PayloadAction<DriverTaskFilter>) {
      state.filter = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getDriverTasksPending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDriverTasksFulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.tasks = payload;
      })
      .addCase(getDriverTasksRejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(getDriversPending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDriversFulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.drivers = payload;
      })
      .addCase(getDriversRejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      }),
});

export const { clearError, setError, setFilter } = driverTaskListSlice.actions;

export const selectIsLoading = (state: RootState) =>
  state.driverTaskList.isLoading;
export const selectError = (state: RootState) => state.driverTaskList.error;
export const selectFilter = (state: RootState) => state.driverTaskList.filter;
export const selectDrivers = (state: RootState) => state.driverTaskList.drivers;
const selectAllTasks = (state: RootState) => state.driverTaskList.tasks;

export const selectToLocations = createSelector(selectAllTasks, (tasks) =>
  tasks
    .map((t) => t.toLocation)
    .reduce((memo, t) => {
      if (memo.indexOf(t) === -1) {
        memo.push(t);
      }
      return memo;
    }, [] as string[])
    .sort()
);

export const selectFromLocations = createSelector(selectAllTasks, (tasks) =>
  tasks
    .map((t) => t.fromLocation)
    .reduce((memo, t) => {
      if (memo.indexOf(t) === -1) {
        memo.push(t);
      }
      return memo;
    }, [] as string[])
    .sort()
);

export const selectTasks = createSelector(
  selectAllTasks,
  selectFilter,
  (tasks, filter) => {
    const filterFn = filterDriverTask(filter);
    return tasks
      .map((t) => ({ ...t }))
      .filter(filterFn)
      .sort(sortByPriority);
  }
);

export const selectTaskDates = createSelector(selectTasks, (tasks) =>
  tasks
    .map((t) => ({ ...t }))
    .sort(sortByDueDate)
    .map((t) => t.dueDate)
    .reduce((memo, t) => {
      if (memo.indexOf(t) === -1) {
        memo.push(t);
      }
      return memo;
    }, [] as string[])
);

function sortByPriority(a: models.DriverTask, b: models.DriverTask) {
  const priorityA = a.priority === 'High' ? 1 : a.priority === 'Normal' ? 2 : 3,
    priorityB = b.priority === 'High' ? 1 : b.priority === 'Normal' ? 2 : 3;

  return priorityA - priorityB;
}

function filterDriverTask(filter: DriverTaskFilter) {
  const {
    showComplete,
    from,
    to,
    priority,
    status,
    assignedTo,
    fromLocation,
    toLocation,
  } = filter;
  return function (task: models.DriverTask) {
    if (!showComplete && equals(task.status, models.CompleteStatus)) {
      return false;
    }

    if (from && task.dueDate < from) {
      return false;
    }

    if (to && task.dueDate > to) {
      return false;
    }

    if (fromLocation && !equals(task.fromLocation, fromLocation)) {
      return false;
    }

    if (toLocation && !equals(task.toLocation, toLocation)) {
      return false;
    }

    if (assignedTo) {
      if (equals(assignedTo, UnassignedDriverKey) && task.assignedTo) {
        return false;
      }

      if (!equals(assignedTo, task.assignedTo?.name)) {
        return false;
      }
    }

    if (priority && !equals(priority, task.priority)) {
      return false;
    }

    if (status && !equals(status, task.status)) {
      return false;
    }

    return true;
  };
}

export default driverTaskListSlice.reducer;
