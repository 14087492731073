import { database } from 'app/database';
import { createProblemDetails } from 'utils/problem-details';
import * as models from './models/model-base';

type KeyType = string | number | string[] | number[];

export class ServiceBase {
  async getAllDocuments<T>(filter: string): Promise<T[]> {
    try {
      const { rows } = await database.db.query<T>(filter, { include_docs: true }),
        // https://stackoverflow.com/a/59726888
        docs = rows.flatMap(row => (row.doc ? [row.doc] : []));

      return docs;
    } catch (e) {
      throw createProblemDetails(e);
    }
  }

  async query<T extends {}>(view: string, startkey?: KeyType, endkey?: KeyType): Promise<T[]> {
    try {
      const options: PouchDB.Query.Options<T, T> = { include_docs: true };
      if (startkey) {
        options.startkey = startkey;
      }
      if (endkey) {
        options.endkey = endkey;
      }

      const { rows } = await database.db.query<T, T>(view, options),
        // https://stackoverflow.com/a/59726888
        docs = rows.flatMap(row => (row.doc ? [row.doc] : []));

      return docs;
    } catch (e) {
      throw createProblemDetails(e);
    }
  }

  async getOneDocument<T>(id: string): Promise<T> {
    try {
      return await database.db.get<T>(id);
    } catch (e) {
      throw createProblemDetails(e);
    }
  }

  async saveDocument<T extends models.ModelBase>(model: T) {
    try {
      const response = model._rev ? await database.db.put<T>(model) : await database.db.post<T>(model);
      if (response.ok) {
        model._id = response.id;
        model._rev = response.rev;
        return model;
      }

      console.error(response);
      throw createProblemDetails('Document was not saved');
    } catch (e) {
      throw createProblemDetails(e);
    }
  }

  async delete(model: models.ModelBase) {
    try {
      if (model._rev) {
        const response = await database.db.remove(model._id, model._rev);

        if (!response.ok) {
          console.error(response);
          throw createProblemDetails('Document was not deleted');
        }
      } else {
        throw createProblemDetails('Could not delete unsaved document.');
      }
    } catch (e) {
      throw createProblemDetails(e);
    }
  }
}
