import { useState } from 'react';
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  NavbarToggler,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from 'reactstrap';
import { useAuth } from 'features/auth/use-auth';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { routes } from './routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function NavMenu() {
  const navigate = useNavigate(),
    auth = useAuth(),
    [isOpen, setIsOpen] = useState(false);

  const handleLogoutClick = () => {
    auth.signout(() => navigate(routes.login.path));
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header>
      <Navbar color="light" expand="md" fixed="top" container={false}>
        <div className="container w-auto mx-auto">
          <NavbarBrand to={routes.home.path} tag={Link}>
            <img src="images/logo.png" alt="Boekestyn Greenhouses" />
          </NavbarBrand>
          <NavbarToggler onClick={toggle}>
            <FontAwesomeIcon icon={['fat', 'bars']} />
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar>
            <Nav className="me-auto" navbar>
              {auth.isInRole('view:orders') && (
                <>
                  <NavItem>
                    <div className="navbar-text nav-link">|</div>
                  </NavItem>
                  <NavItem>
                    <NavLink to={routes.orders.path} tag={Link}>
                      <FontAwesomeIcon icon={['fat', 'file-invoice']} />
                      &nbsp; Orders
                    </NavLink>
                  </NavItem>
                </>
              )}
              {auth.isInRole('view:driver-tasks') && (
                <>
                  <NavItem>
                    <div className="navbar-text nav-link">|</div>
                  </NavItem>
                  <NavItem>
                    <NavLink to={routes.driverTasks.list.path} tag={Link}>
                      <FontAwesomeIcon icon={['fat', 'truck-fast']} />
                      &nbsp; Driver Tasks
                    </NavLink>
                  </NavItem>
                </>
              )}
              <NavItem>
                <div className="navbar-text nav-link">|</div>
              </NavItem>
              <UncontrolledDropdown inNavbar nav>
                <DropdownToggle caret nav>
                  <FontAwesomeIcon icon={['fat', 'cogs']} />
                </DropdownToggle>
                <DropdownMenu>
                  {auth.isInRole('view:plants') && (
                    <DropdownItem tag={Link} to={routes.plants.path}>
                      <FontAwesomeIcon icon={['fat', 'seedling']} fixedWidth />
                      &nbsp; Plants
                    </DropdownItem>
                  )}
                  {auth.isInRole('view:zones') && (
                    <DropdownItem tag={Link} to={routes.zones.path}>
                      <FontAwesomeIcon
                        icon={['fat', 'map-location-dot']}
                        fixedWidth
                      />
                      &nbsp; Zones
                    </DropdownItem>
                  )}
                  {auth.isInRole('view:customers') && (
                    <DropdownItem tag={Link} to={routes.customers.path}>
                      <FontAwesomeIcon icon={['fat', 'user-tie']} fixedWidth />
                      &nbsp; Customers
                    </DropdownItem>
                  )}
                  {(auth.isInRole('view:plants') ||
                    auth.isInRole('view:zones') ||
                    auth.isInRole('view:customers')) && (
                    <DropdownItem divider />
                  )}
                  {auth.isInRole('admin:users') && (
                    <>
                      <DropdownItem tag={Link} to={routes.users.path}>
                        <FontAwesomeIcon icon={['fat', 'user']} fixedWidth />
                        &nbsp; Users
                      </DropdownItem>
                      <DropdownItem divider />
                    </>
                  )}
                  <DropdownItem
                    tag={Link}
                    to={routes.login.path}
                    onClick={handleLogoutClick}>
                    <FontAwesomeIcon icon={['fat', 'sign-out']} fixedWidth />
                    &nbsp; Logout
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    </header>
  );
}
