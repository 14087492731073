import { api } from './api-base';
import * as models from './models/auth';
import { configuration } from 'features/auth/configuration';

class AuthService {
  async login(username: string, password: string): Promise<models.CouchUserDoc | null> {
    const url = `${configuration.remote_server}/_session`,
      body = `name=${encodeURI(username)}&password=${encodeURI(password)}`,
      authorization = getBasicAuth(username, password),
      headers = { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': authorization },
      session = await api.post<models.CouchSessionDoc>(url, body, { headers }),
      user = await api.get<models.CouchUserDoc>(`${configuration.remote_server}/_users/org.couchdb.user:${session.name}`, { headers });
   
    return user;
  }

  async getAllUsers(user: models.UserInfo): Promise<models.UserDoc[]> {
    const url = `${configuration.remote_server}/_users/_design/user-filters/_view/boekestyn-users?include_docs=true`,
      authorization = getBasicAuth(user.name, user.password),
      headers = { Authorization: authorization},
      response = await api.get<PouchDB.Query.Response<models.CouchUserDoc>>(url, { headers }),
      users = response.rows.flatMap(row => row.doc ? [row.doc] : []);

    return users;
  }

  async updateUser(credentials: models.UserInfo, user: models.CouchUserDoc): Promise<boolean> {
    const model: any = {...user},
      url = `${configuration.remote_server}/_users/${user._id}`,
      authorization = getBasicAuth(credentials.name, credentials.password),
      headers = { Authorization: authorization},
      response = await api.put<PouchDB.Core.Response>(url, model, { headers });

    return response.ok;
  }

  async updatePassword(credentials: models.UserInfo, user: models.CouchUserDoc, password: string): Promise<boolean> {
    const model: any = {...user};

    delete model.password_sha;
    delete model.salt;
    model.password = password;

    const url = `${configuration.remote_server}/_users/${user._id}`,
      authorization = getBasicAuth(credentials.name, credentials.password),
      headers = { Authorization: authorization},
      response = await api.put<PouchDB.Core.Response>(url, model, { headers });

    return response.ok;
  }
}

export function getBasicAuth(username: string, password: string) {
  const data = `${username}:${password}`,
      header = `Basic ${window.btoa(data)}`;

  return header;
}

export const authApi = new AuthService();
