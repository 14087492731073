export enum EventTypes {
  error = 'error',
  authenticated = 'authenticated',
  plantsUpdated = 'plants-updated',
  ordersUpdated = 'orders-updated',
  customersUpdated = 'customers-updated',
  zonesUpdated = 'zones-updated',
  driverTasksUpdated = 'driver-tasks-updated',
}

class Events {
  private target = new EventTarget();

  on(type: EventTypes, listener?: EventListener) {
    this.target.addEventListener(type, listener || null);
  }

  off(type: EventTypes, listener?: EventListener) {
    this.target.removeEventListener(type, listener || null);
  }

  emit(type: EventTypes, detail?: any) {
    const event = detail ? new CustomEvent(type, { detail }) : new Event(type);
    this.target.dispatchEvent(event);
  }
}

export const events = new Events();
