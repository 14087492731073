import { axios } from 'boot/axios';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { serializeError } from 'serialize-error';
import { createProblemDetails, isProblemDetails } from 'utils/problem-details';

class ApiBase {
  get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      return axios
        .get<T>(url, config)
        .then(r => checkResult(r, resolve, reject))
        .catch((e: AxiosError) => handleError(e, reject));
    });
  }
  post<T>(url: string, payload: any = {}, config?: AxiosRequestConfig): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      return axios
        .post<T>(url, payload, config)
        .then(r => checkResult(r, resolve, reject))
        .catch((e: AxiosError) => handleError(e, reject));
    });
  }
  put<T>(url: string, payload: any = {}, config?: AxiosRequestConfig): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      return axios
        .put<T>(url, payload, config)
        .then(r => checkResult(r, resolve, reject))
        .catch((e: AxiosError) => handleError(e, reject));
    });
  }
  delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      return axios
        .delete<T>(url, config)
        .then(r => checkResult(r, resolve, reject))
        .catch((e: AxiosError) => handleError(e, reject));
    });
  }
}

function checkResult<T>(
  response: AxiosResponse<T> | AxiosError | Error,
  resolve: (data: T) => void,
  reject: (reason?: any) => void
): T | void {
  if (!isSuccess(response)) {
    if (isAxiosError(response)) {
      if (response.response) {
        if (isProblemDetails(response.response.data)) {
          return reject(response.response.data);
        }

        if (typeof response.response.data === 'string') {
          return reject(createProblemDetails(response.response.data));
        }
      }
    } else {
      if (response) {
        if (isError(response)) {
          const error = serializeError(response);
          return reject(createProblemDetails(error.name || 'Error', error.message));
        }

        if (response.data && isProblemDetails(response.data)) {
          return reject(response.data);
        }

        if (typeof response.data === 'string') {
          return reject(createProblemDetails(response.data));
        }
      }

      if (response && response.data) {
        return reject(response.data);
      }

      return reject(response.data);
    }
  }

  return resolve((response as AxiosResponse).data);
}

export function handleError(e: AxiosError, reject: (reason?: any) => void) {
  console.error(e);

  if (e.response) {
    if (isProblemDetails(e.response.data)) {
      return reject(e.response.data);
    }

    if (typeof e.response.data === 'string') {
      return reject(createProblemDetails(e.response.data));
    }
  }

  if (e.response && e.response.data) {
    const data = e.response.data as any;
    if (typeof data.error === 'string' && typeof data.reason === 'string') {
      return reject(createProblemDetails(data.error, data.reason));
    }

    return reject(e.response.data);
  }

  return reject(e.message);
}

function isSuccess(response: AxiosResponse<any> | AxiosError | Error) {
  return (response as AxiosResponse).status >= 200 && (response as AxiosResponse).status < 300;
}

function isAxiosError(response: AxiosResponse<any> | AxiosError | Error): response is AxiosError {
  return 'isAxiosError' in response && response.isAxiosError;
}

function isError(response: any): response is Error {
  return response instanceof Error;
}

export const api = new ApiBase();
