import { lazy } from 'react';

export const routes = {
  home: {
    path: '/',
    element: lazy(() => import('pages/Index')),
  },
  login: {
    path: '/login',
    element: lazy(() => import('features/auth/Login')),
  },
  orders: {
    path: '/orders',
    element: lazy(() => import('features/orders/List')),
    routes: {
      new: () => '/orders/new',
      byStickDate: {
        path: '/orders/stick-date',
        element: lazy(() => import('features/orders/ByStickDate')),
      },
      byFlowerDate: {
        path: '/orders/flower-date',
        element: lazy(() => import('features/orders/ByFlowerDate')),
      },
      bySpaceDate: {
        path: '/orders/space-date',
        element: lazy(() => import('features/orders/BySpaceDate')),
      },
      byPinchDate: {
        path: '/orders/pinch-date',
        element: lazy(() => import('features/orders/ByPinchDate')),
      },
      detail: {
        path: '/orders/:id',
        element: lazy(() => import('features/orders/Detail')),
        to: (id: string) => `/orders/${id}`,
      },
    },
  },
  plants: {
    path: '/plants',
    element: lazy(() => import('features/plants/List')),
    routes: {
      new: () => '/plants/new',
      detail: {
        path: '/plants/:id',
        element: lazy(() => import('features/plants/Detail')),
        to: (id: string) => `/plants/${id}`,
      },
    },
  },
  zones: {
    path: '/zones',
    element: lazy(() => import('features/zones/List')),
    routes: {
      new: () => '/zones/new',
      detail: {
        path: '/zones/:id',
        element: lazy(() => import('features/zones/Detail')),
        to: (id: string) => `/zones/${id}`,
      },
    },
  },
  customers: {
    path: '/customers',
    element: lazy(() => import('features/customers/List')),
    routes: {
      new: () => '/customers/new',
      detail: {
        path: '/customers/:id',
        element: lazy(() => import('features/customers/Detail')),
        to: (id: string) => `/customers/${id}`,
      },
    },
  },
  users: {
    path: '/users',
    element: lazy(() => import('features/users/List')),
    routes: {
      detail: {
        path: '/users/:name',
        element: lazy(() => import('features/users/Detail')),
        to: (name: string) => `/users/${name}`,
      },
    },
  },
  driverTasks: {
    list: {
      path: '/driver-tasks',
      element: lazy(() => import('features/driver-tasks/List')),
    },
    new: {
      path: '/driver-tasks/new',
      element: lazy(() => import('features/driver-tasks/New')),
    },
    detail: {
      path: '/driver-tasks/:id',
      to: (id: string) => `/driver-tasks/${id}`,
      element: lazy(() => import('features/driver-tasks/Detail')),
    },
  },
};
