import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Zone } from 'api/models/zones';
import { RootState } from 'app/store';
import { sortBy } from 'utils/sort';

export interface ZonesState {
  zones: Zone[];
}

const initialState: ZonesState = {
  zones: []
};

export const zonesSlice = createSlice({
  name: 'zones',
  initialState,
  reducers: {
    setZones(state, action: PayloadAction<Zone[]>) {
      state.zones = action.payload;
    }
  }
});

export const { setZones } = zonesSlice.actions;

export const selectZones = (state: RootState) => state.zones.zones.map(z => ({...z})).sort(sortBy('name'));

export default zonesSlice.reducer;
