import 'boot';
import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from '../features/auth/auth-provider';
import './App.scss';
import { RequireAuth } from 'features/auth/require-auth';
import { routes } from './routes';
import { Layout } from './Layout';
import { Login } from 'features/auth/Login';
import { plantApi } from 'api/plant-service';
import { orderApi } from 'api/order-service';
import { setPlants } from 'features/plants/plants-slice';
import { setCustomers } from 'features/customers/customers-slice';
import { setOrders } from 'features/orders/orders-slice';
import { setZones } from 'features/zones/zones-slice';
import { events, EventTypes } from './events';
import { customerApi } from 'api/customer-service';
import { zoneApi } from 'api/zone-service';

function App() {
  const dispatch = useDispatch();

  const refreshPlants = useCallback(() => {
    plantApi.getAll().then((plants) => dispatch(setPlants(plants)));
  }, [dispatch]);

  const refreshOrders = useCallback(() => {
    orderApi.getAll().then((orders) => dispatch(setOrders(orders)));
  }, [dispatch]);

  const refreshCustomers = useCallback(() => {
    customerApi.getAll().then((customers) => dispatch(setCustomers(customers)));
  }, [dispatch]);

  const refreshZones = useCallback(() => {
    zoneApi.getAll().then((zones) => dispatch(setZones(zones)));
  }, [dispatch]);

  useEffect(() => {
    events.on(EventTypes.plantsUpdated, refreshPlants);
    events.on(EventTypes.ordersUpdated, refreshOrders);
    events.on(EventTypes.customersUpdated, refreshCustomers);
    events.on(EventTypes.zonesUpdated, refreshZones);

    refreshPlants();
    refreshOrders();
    refreshCustomers();
    refreshZones();
  }, [refreshCustomers, refreshOrders, refreshPlants, refreshZones]);

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path={routes.login.path} element={<Login />} />
          <Route element={<Layout />}>
            <Route
              path={routes.home.path}
              element={<RequireAuth element={routes.home.element} />}
            />
            <Route
              path={routes.orders.path}
              element={<RequireAuth element={routes.orders.element} />}
            />
            <Route
              path={routes.orders.routes.byStickDate.path}
              element={
                <RequireAuth
                  element={routes.orders.routes.byStickDate.element}
                />
              }
            />
            <Route
              path={routes.orders.routes.byFlowerDate.path}
              element={
                <RequireAuth
                  element={routes.orders.routes.byFlowerDate.element}
                />
              }
            />
            <Route
              path={routes.orders.routes.bySpaceDate.path}
              element={
                <RequireAuth
                  element={routes.orders.routes.bySpaceDate.element}
                />
              }
            />
            <Route
              path={routes.orders.routes.byPinchDate.path}
              element={
                <RequireAuth
                  element={routes.orders.routes.byPinchDate.element}
                />
              }
            />
            <Route
              path={routes.orders.routes.detail.path}
              element={
                <RequireAuth element={routes.orders.routes.detail.element} />
              }
            />
            <Route
              path={routes.plants.path}
              element={<RequireAuth element={routes.plants.element} />}
            />
            <Route
              path={routes.plants.routes.detail.path}
              element={
                <RequireAuth element={routes.plants.routes.detail.element} />
              }
            />
            <Route
              path={routes.zones.path}
              element={<RequireAuth element={routes.zones.element} />}
            />
            <Route
              path={routes.zones.routes.detail.path}
              element={
                <RequireAuth element={routes.zones.routes.detail.element} />
              }
            />
            <Route
              path={routes.customers.path}
              element={<RequireAuth element={routes.customers.element} />}
            />
            <Route
              path={routes.customers.routes.detail.path}
              element={
                <RequireAuth element={routes.customers.routes.detail.element} />
              }
            />
            <Route
              path={routes.users.path}
              element={<RequireAuth element={routes.users.element} />}
            />
            <Route
              path={routes.users.routes.detail.path}
              element={
                <RequireAuth element={routes.users.routes.detail.element} />
              }
            />
            <Route
              path={routes.driverTasks.list.path}
              element={
                <RequireAuth element={routes.driverTasks.list.element} />
              }
            />
            <Route
              path={routes.driverTasks.new.path}
              element={<RequireAuth element={routes.driverTasks.new.element} />}
            />
            <Route
              path={routes.driverTasks.detail.path}
              element={
                <RequireAuth element={routes.driverTasks.detail.element} />
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
