export function sortBy(propertyName: string, direction?: string) {
  const factor = direction === 'descending' ? -1 : 1;

  return function(a: any, b: any) {
    if(a == null && b == null) return 0;
    if(a != null && b == null) return 1 * factor;
    if(a == null && b != null) return -1 * factor;

    var first = a[propertyName],
        second = b[propertyName];

      if(typeof first === 'number' && typeof(second === 'number')) {
        return (first < second ? -1 : 1) * factor;
      }

      if(typeof first === 'boolean' && typeof(second === 'boolean')) {
        return ((first === second) ? 0 : first ? 1 : -1) * factor;
      }

      if(toString.call(first) === '[object Date]' && toString.call(second) === '[object Date]') {
        return (first < second ? -1 : first > second ? 1 : 0) * factor;
      }

      first = (first || '').toString().toLowerCase();
      second = (second || '').toString().toLowerCase();
      return (first < second ? -1 : first > second ? 1 : 0) * factor;
  }
}

export function sortSizeName(a: string, b: string) {
  const intA = parseFloat(a),
    intB = parseFloat(b);

  if(intA && intB) {
    return intA < intB ? -1 : intA > intB ? 1 : 0;
  }

  return a < b ? -1 : a > b ? 1 : 0;
}
