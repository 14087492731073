import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Plant } from 'api/models/plants';
import { RootState } from 'app/store';
import { sortBy, sortSizeName } from 'utils/sort';

export interface PlantsState {
  plants: Plant[];
}

const initialState: PlantsState = {
  plants: []
};

export const plantsSlice = createSlice({
  name: 'plants',
  initialState,
  reducers: {
    setPlants(state, action: PayloadAction<Plant[]>) {
      state.plants = action.payload;
    }
  }
});

export const { setPlants } = plantsSlice.actions;

export const selectPlants = (state: RootState) => state.plants.plants.map(p => ({...p})).sort(sortPlant);

export default plantsSlice.reducer;

const sortByCrop = sortBy('crop');

function sortPlant(a: Plant, b: Plant) {
  return sortByCrop(a, b) || sortSizeName(a.size, b.size);
}