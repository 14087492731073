import { guid } from 'utils/guid';
import { ModelBase } from './model-base';

export const CustomerType = 'customer';

export const WeeklyName = 'Weekly';
export const WeeklyAbbreviation = 'Wk';

export interface Customer extends ModelBase {
  type: string;
  abbreviation: string;
  name: string;
}

export function createCustomer(): Customer {
  return {
    _id: guid(),
    type: CustomerType,
    abbreviation: '',
    name: ''
  };
}

export function weeklyCustomer(): Customer {
  return {
    _id: guid(),
    type: CustomerType,
    abbreviation: WeeklyAbbreviation,
    name: WeeklyName
  };
}
